<template>
	<div page="account-settings">
		<SettingsHeader title="Account settings"	
		@on-click-left="goOut">
			<template v-slot:left-button>
				<span class="iconify" 
					data-icon="material-symbols:arrow-left-alt-rounded" 
					data-width="21" 
					data-height="21">
				</span>
			</template>
		</SettingsHeader>
	</div>
</template>

<script>
import SettingsHeader from '../components/settings/SettingsHeader.vue';

export default {
	name: 'AccountSettings',
	components: {
		SettingsHeader,
	},
	data() {
		return {
		
		};
	},

	methods: {
		goOut(){
			const originPage = JSON.parse(localStorage.getItem('originPage-account'))
			if (originPage) {
				localStorage.removeItem('originPage-attenders')
				this.$router.replace(originPage)
				this.$router.back()
				return ;
			}
			this.$router.push({
				name: 'calendar-schedule',
				params: {
					calendarUid: this.$route.params.calendarUid
				}
			})
		}
	},
};
</script>